<template>
  <div class="ygx_product_index">
    <div style="width:100%;margin-bottom:100px"><img src="../assets/ygx/ygx_product_title_bg.jpeg" style="width:100%" /></div>

    <div class="ygx_product_zqd_menu">
          <div class="ygx_product_zqd_menu_item"
            v-for="(item, index) in productList"
            :key="index"
            @mouseover="meunMouseOver(index)"
            @mouseleave="meunMouseLeave(index)"
          >
            <div :class=" index == meunIndex ? 'ygx_product_zqd_menu_img_sel' : 'ygx_product_zqd_menu_img'">
              <img style="width: 100%;height:100%" :src="index == meunIndex ? item.selImg : item.img" />
            </div>
            <div :class="index == meunIndex ? 'ygx_product_zqd_menu_title_sel' : 'ygx_product_zqd_menu_title'">{{ item.title }}</div>
          </div>
    </div>
    
    <div class="ygx_product_zqd_banner">
      <el-carousel indicator-position="outside" height="400px" :interval="10000"  @change="bannerChange"  ref="plancarousel" :initial-index="bannerIndex" >
            <el-carousel-item v-for="(item,index) in productList" :key="index">
                <div class="ygx_product_zqd_banner_item">
                    <div class="ygx_product_zqd_banner_item_img">
                      <img style="width:100%;height:100%" :src="item.bannerImg" />
                    </div>
                    <div class="ygx_product_zqd_banner_item_right">
                      <div class="ygx_product_zqd_banner_item_right_title">{{item.title}}</div>
                      <div class="ygx_product_zqd_banner_item_right_line"></div>
                      <div class="ygx_product_zqd_banner_item_right_desc">{{item.desc}}</div>
                      <div class="ygx_product_zqd_banner_item_right_tip">
                        <div class="ygx_product_zqd_banner_item_right_tip_item" v-for="(tipItem,tipIndex) in item.tips" :key="tipIndex">
                          <div class="ygx_product_zqd_banner_item_right_tip_item_icon">
                            <img style="width:100%;height:100%" :src="tipItem.icon" alt="">
                          </div>
                      <div class="ygx_product_zqd_banner_item_right_tip_item_title">{{tipItem.title}}</div>
                        </div>
                      </div>
                    </div>
                </div>
            </el-carousel-item>
            </el-carousel>
    </div>

    <div class="ygx_product_zqd_detail">
      <div class="ygx_product_zqd_detail_body">
        <div class="ygx_product_zqd_detail_item_body">
          <template v-for="(item,index) in detailInfo.itemList">
            <div class="ygx_product_zqd_detail_item"  :key="index" v-if="(index % 2 == 0)">
            <div class="ygx_product_zqd_detail_item_top" >
              <img class="ygx_product_zqd_detail_item_top_img" :src="item.img" />
              <div class="ygx_product_zqd_detail_item_top_title">{{item.title}}</div>
            </div>
            <div class="ygx_product_zqd_detail_item_top_msg">
              {{item.subTitle}}
            </div>
        </div>
          </template>
          
        </div>
        <div class="ygx_product_zqd_detail_body_img">
        <img style="width:100%" :src="detailInfo.centerImg" alt="" />
      </div>
      <div class="ygx_product_zqd_detail_item_body">
        <template v-for="(item,index) in detailInfo.itemList">
          <div class="ygx_product_zqd_detail_item"  :key="index" v-if="(index % 2 == 1)">

          <div class="ygx_product_zqd_detail_item_top_right" >
            <div class="ygx_product_zqd_detail_item_top_title_right">{{item.title}}</div>
              <img class="ygx_product_zqd_detail_item_top_img_right" :src="item.img" />
            </div>
            <div class="ygx_product_zqd_detail_item_top_msg">
              {{item.subTitle}}
            </div>

        </div>
        </template>
        
      </div>
      </div>
      
      
    </div>




  
    

  </div>
</template>


<script>



export default {
  components: {
 
  },
  watch: {
            meunIndex: function (nval, oval) {
              let that = this;
                if (nval != null) {
                    var dataInfo = that.productList[nval];
                    that.$set(that,"detailInfo",dataInfo);
                }
            }
        },
computed: {
            
        },
  data() {
    return {
      bannerIndex:0,
      meunIndex: 0,
      detailInfo:{
        title: "灵活用工",
          subTitle: "用工合规、类型丰富、资源共享",
          tips:[
            {icon:require("@/assets/ygx/product_tip_0_0.png"),title:"用工合规"},
            {icon:require("@/assets/ygx/product_tip_0_1.png"),title:"类型丰富"},
            {icon:require("@/assets/ygx/product_tip_0_2.png"),title:"资源共享"}
          ],
          desc: '通过整合人力资源分散产能，与企业的灵活用工需求进行大数据在线智能匹配。为传统企业进行互 联网化改造，推动企业端供给侧改革，为企业“降成本，促活力”，为个人“促创业，增收入”。',
          img: require("@/assets/ygx/product_0.png"),
          selImg: require("@/assets/ygx/sel_product_0.png"),
          centerImg:require("@/assets/ygx/product_center_0.png"),
          bannerImg:require("@/assets/ygx/product_banner_0.png"),
          itemList: [
            {
              title: "资源广泛",
              subTitle:
                "一健快速下单 海量资源可选 大数据智能匹配 人力公司数量多、类型广。",
              img: require("@/assets/ygx/product_0_0_0.png"),
              selImg: require("@/assets/ygx/product_0_0_1.png"),
              selTag: false,
            },
            {
              title: "成本降低",
              subTitle:
                "税收政策优惠 社保筹划降本 企业进项增加 综合降本20%以上。",
              img: require("@/assets/ygx/product_0_1_0.png"),
              selImg: require("@/assets/ygx/product_0_1_1.png"),
              selTag: false,
            },
            {
              title: "灵活合规",
              subTitle:
                "财务税务灵活合规 用工灵活便捷 流程合规 风险可控 不再担心用工、社保、财税。",
              img: require("@/assets/ygx/product_0_2_0.png"),
              selImg: require("@/assets/ygx/product_0_2_1.png"),
              selTag: false,
            },
          ],
      },//选中的详情信息  
      productList: [
        {
          title: "灵活用工",
          subTitle: "用工合规、类型丰富、资源共享",
          tips:[
            {icon:require("@/assets/ygx/product_tip_0_0.png"),title:"用工合规"},
            {icon:require("@/assets/ygx/product_tip_0_1.png"),title:"类型丰富"},
            {icon:require("@/assets/ygx/product_tip_0_2.png"),title:"资源共享"}
          ],
          desc: '通过整合人力资源分散产能，与企业的灵活用工需求进行大数据在线智能匹配。为传统企业进行互 联网化改造，推动企业端供给侧改革，为企业“降成本，促活力”，为个人“促创业，增收入”。',
          img: require("@/assets/ygx/product_0.png"),
          selImg: require("@/assets/ygx/sel_product_0.png"),
          centerImg:require("@/assets/ygx/product_center_0.png"),
          bannerImg:require("@/assets/ygx/product_banner_0.png"),
          itemList: [
            {
              title: "资源广泛",
              subTitle:
                "一健快速下单 海量资源可选 大数据智能匹配 人力公司数量多、类型广。",
              img: require("@/assets/ygx/product_0_0_0.png"),
              selImg: require("@/assets/ygx/product_0_0_1.png"),
              selTag: false,
            },
            {
              title: "成本降低",
              subTitle:
                "税收政策优惠 社保筹划降本 企业进项增加 综合降本20%以上。",
              img: require("@/assets/ygx/product_0_1_0.png"),
              selImg: require("@/assets/ygx/product_0_1_1.png"),
              selTag: false,
            },
            {
              title: "灵活合规",
              subTitle:
                "财务税务灵活合规 用工灵活便捷 流程合规 风险可控 不再担心用工、社保、财税。",
              img: require("@/assets/ygx/product_0_2_0.png"),
              selImg: require("@/assets/ygx/product_0_2_1.png"),
              selTag: false,
            },
          ],
        },
        {
          title: "产业园运营",
          subTitle: "市中心、政企合作、政策好",
          tips:[
            {icon:require("@/assets/ygx/product_tip_1_0.png"),title:"市中心"},
            {icon:require("@/assets/ygx/product_tip_1_1.png"),title:"政企合作"},
            {icon:require("@/assets/ygx/product_tip_1_2.png"),title:"政策好"}
          ],
          desc: "实训基地、营销中心、营运中心、产业园服务公司、技术中心、人力资源部、结算中心等职能部门，支撑业务快速成长并给入园的客户提供优质的有保障的服务。",
          img: require("@/assets/ygx/product_1.png"),
          selImg: require("@/assets/ygx/sel_product_1.png"),
          centerImg:require("@/assets/ygx/product_center_1.png"),
          bannerImg:require("@/assets/ygx/product_banner_1.png"),
          itemList: [
            {
              title: "企业孵化注册",
              subTitle: "为客户提供人力服务型企业的孵化 注册一条龙服务。",
              img: require("@/assets/ygx/product_1_0_0.png"),
              selImg: require("@/assets/ygx/product_1_0_1.png"),
              selTag: false,
            },
            {
              title: "政策支持优势",
              subTitle: "园区落地企业共享优惠政策，协助 申报政府项目奖励和补贴。",
              img: require("@/assets/ygx/product_1_1_0.png"),
              selImg: require("@/assets/ygx/product_1_1_1.png"),
              selTag: false,
            },
            {
              title: "运营服务托管",
              subTitle:
                "税收政策优惠 社保筹划降本 企业进项增加 综合降本20%以上。",
              img: require("@/assets/ygx/product_1_2_0.png"),
              selImg: require("@/assets/ygx/product_1_2_1.png"),
              selTag: false,
            },
            {
              title: "技术资金支持",
              subTitle:
                "为用户提供HR SAAS服务，通过系 统大数据提供资金支持服务和增值 性产品服务。",
              img: require("@/assets/ygx/product_1_3_0.png"),
              selImg: require("@/assets/ygx/product_1_3_1.png"),
              selTag: false,
            },
            {
              title: "资源共享",
              subTitle:
                "客户资源与需求共享给园区合作的 落地公司来服务 实习生、对口扶贫输出人员资源对 接至落地公司共享。",
              img: require("@/assets/ygx/product_1_4_0.png"),
              selImg: require("@/assets/ygx/product_1_4_1.png"),
              selTag: false,
            },
            {
              title: "场地硬件共享",
              subTitle: "提供场地、人才补贴政策、个体户 集群注册等。",
              img: require("@/assets/ygx/product_1_5_0.png"),
              selImg: require("@/assets/ygx/product_1_5_1.png"),
              selTag: false,
            },
          ],
        },
        {
          title: "异地岗位共享",
          subTitle: "招聘支持、拎包入住、成本优势",
          tips:[
            {icon:require("@/assets/ygx/product_tip_2_0.png"),title:"招聘支持"},
            {icon:require("@/assets/ygx/product_tip_2_1.png"),title:"拎包入住"},
            {icon:require("@/assets/ygx/product_tip_2_2.png"),title:"成本优势"}
          ],
          desc: "为一二线城市企业提供工作异地转移服务，基于数字化实现人力资源异地共享，降低企业用工成本。",
          img: require("@/assets/ygx/product_2.png"),
          selImg: require("@/assets/ygx/sel_product_2.png"),
          centerImg:require("@/assets/ygx/product_center_2.png"),
          bannerImg:require("@/assets/ygx/product_banner_2.png"),
          itemList: [
            {
              title: '为城市增"人气"',
              subTitle:
                "可以为城市获取大量的异地 优质岗位落地，吸引人才进 入或者留在城市。",
              img: require("@/assets/ygx/product_2_0_0.png"),
              selImg: require("@/assets/ygx/product_2_0_1.png"),
              selTag: false,
            },
            {
              title: "为企业降成本",
              subTitle:
                "一二线城市用人成本高，人员 流动性大，采用异地岗位共享 可以为企业节省大量的用工成 本和时间。",
              img: require("@/assets/ygx/product_2_1_0.png"),
              selImg: require("@/assets/ygx/product_2_1_1.png"),
              selTag: false,
            },
            {
              title: "为学生找方向",
              subTitle:
                "通过异地岗位共享，可以把很多新的 岗位引入，让学成从实习中找到学习 方向，更好的为毕业后就业打好基础。",
              img: require("@/assets/ygx/product_2_2_0.png"),
              selImg: require("@/assets/ygx/product_2_2_1.png"),
              selTag: false,
            },
            {
              title: "为高校提就业",
              subTitle:
                "可以为高校毕业生和实习生增加大量的用人 和就业机会，有助于校企结合。",
              img: require("@/assets/ygx/product_2_3_0.png"),
              selImg: require("@/assets/ygx/product_2_3_1.png"),
              selTag: false,
            },
          ],
        },
      ],
    };
  },

  created() {},

  mounted() {
    let that = this;
        if (this.$route.query && this.$route.query.index != null) {
        this.meunIndex = this.$route.query.index;
        this.bannerIndex = this.$route.query.index;
         var dataInfo = that.productList[nval];
        that.$set(that,"detailInfo",dataInfo);
        this.$refs.plancarousel.setActiveItem(index)
    }
  },

  methods: {

    //鼠标移到了产品菜单
    meunMouseOver(index) {
      this.meunIndex = index;
      this.$refs.plancarousel.setActiveItem(index)
    },
    //鼠标移到了产品菜单
    meunMouseLeave(index) {
      
    },
    //切换了banner
    bannerChange(nIndex,oIndex){
      this.meunIndex = nIndex;
    },

    
    
  },
};
</script>


<style>
.ygx_product_index {
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  background-color: #f6f8fa;
}

.ygx_product_zqd_menu {
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}


.ygx_product_zqd_menu_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  width: 30%;
}




.ygx_product_zqd_menu_img {
  width: 140px;
  height: 140px;

}

.ygx_product_zqd_menu_img_sel {
  width: 140px;
  height: 140px;
   box-shadow: 0px 2px 43px 0px rgba(21, 113, 179, 0.16);
opacity: 0.97;
border-radius: 70px;
}

.ygx_product_zqd_menu_title {
 font-size: 26px;
font-family: Source Han Sans SC;
font-weight: 400;
color: #111111;
  margin-top: 20px;
}

.ygx_product_zqd_menu_title_sel{
  font-size: 30px;
font-family: Source Han Sans SC;
font-weight: 400;
color: #0376F7;
  margin-top: 20px;
}

/* banner 样式 */
.ygx_product_zqd_banner{

  width: 1200px;
  background-color: #fff;
  box-shadow: 0px 2px 43px 0px rgba(21, 113, 179, 0.16);
opacity: 0.97;
margin: 100px 0px;
}


.ygx_product_zqd_banner_item{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
}

.ygx_product_zqd_banner_item_img{
  margin: 0px 20px 0px 100px;
  width: 300px;
}

.ygx_product_zqd_banner_item_right{
  flex: 2;
  margin: 0px 100px 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.ygx_product_zqd_banner_item_right_title{
  font-size: 34px;
font-family: Source Han Sans SC;
font-weight: bold;
color: #111111;

}

.ygx_product_zqd_banner_item_right_line{
  width: 161px;
height: 1px;
background: #434343;
margin: 20px 0px;
}

.ygx_product_zqd_banner_item_right_desc{
  font-size: 20px;
font-family: Source Han Sans SC;
font-weight: 400;
height: 120px;
color: #454545;
}

.ygx_product_zqd_banner_item_right_tip{
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ygx_product_zqd_banner_item_right_tip_item{
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ygx_product_zqd_banner_item_right_tip_item_icon{
  width: 40px;
height: 40px;
}

.ygx_product_zqd_banner_item_right_tip_item_title{
  font-size: 26px;
font-family: Source Han Sans SC;
font-weight: 400;
color: #333333;
margin-left: 20px;
} 

/* 底部的显示 */

.ygx_product_zqd_detail{
  background-image: url("../assets/ygx/product_zqd_bottom_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ygx_product_zqd_detail_body{
  width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 60px 0px;
}

.ygx_product_zqd_detail_body_img{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 414px;
margin: 0px 100px;
}

.ygx_product_zqd_detail_item{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 80px 0px;
}

.ygx_product_zqd_detail_item_top{
  display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.ygx_product_zqd_detail_item_top_img{
width: 50px;
margin-right: 30px;
}

.ygx_product_zqd_detail_item_top_title{
  font-size: 28px;
font-family: Source Han Sans SC;
font-weight: 400;
color: #111111;
}

.ygx_product_zqd_detail_item_top_right{
  display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.ygx_product_zqd_detail_item_top_img_right{
width: 50px;
margin-left: 30px;
}

.ygx_product_zqd_detail_item_top_title_right{
  font-size: 28px;
font-family: Source Han Sans SC;
font-weight: 400;
color: #111111;
}

.ygx_product_zqd_detail_item_top_msg{
  font-size: 20px;
font-family: Source Han Sans SC;
font-weight: 400;
color: #454545;
}

.ygx_product_zqd_detail_item_body{
  flex: 2;
  justify-content: space-around;
  flex-direction: column;
}

</style>