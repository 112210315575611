import Vue from 'vue'
import Router from 'vue-router'

import ygxIndex from './ygxIndex.vue'

import ygxNews from './ygxNews.vue'
import ygxNewsDetail from './ygxNewsDetail.vue'
import ygxProductDetail from './ygxProductDetail.vue'

Vue.use(Router)


export default new Router({
    routes: [

        {path: '*', redirect: '/ygx'},
        {//系统入口页面，
            path: '/ygx/:searchValue?',
            name: 'ygx',
            props: true,
            meta: {
                title: '云工享'
            },
            component: ygxIndex

        },
        {
            path: '/ygxProductDetail',
            name: 'ygxProductDetail',
            props: true,
            meta: {
                title: '产品介绍'
            },
            component: ygxProductDetail

        },
        {
            path: '/ygxNews',
            name: 'ygxNews',
            props: true,
            meta: {
                title: '新闻中心'
            },
            component: ygxNews

        },
        {
            path: '/ygxNewsDetail/:id?',
            name: 'ygxNewsDetail',
            props: true,
            meta: {
                title: '新闻详情'
            },
            component: ygxNewsDetail

        }

    ]
})
