<template>
  <div class="ygx_body_index">
    <div id="ygx_banner" class="ygx_banner">
      <div class="ygx_banner_body">
        <img src="../assets/ygx/ygx_banner_b.png"  class="ygx_banner_img" />
       
        <div class="ygx_product_bottom">
        <div class="ygx_product_bottom_item" v-for="(item, index) in productTagList" :key="index">
          <div class="ygx_product_bottom_subTitle">{{ item.subTitle }}</div>
          <div class="ygx_product_bottom_title">{{ item.title }}</div>
        </div> 
      </div>

      </div>
    </div>
    <div style="width:100%"><img src="../assets/ygx/ygx_banner_c.png" style="width:100%" alt=""></div>

    <div id="ygx_product" class="ygx_product">
      <div class="ygx_product_body">
        <div class="ygx_product_menu">
          <div
            class="ygx_product_menu_item"
            v-for="(item, index) in productList"
            :key="index"
            @mouseover="meunMouseOver(index)"
            @mouseleave="meunMouseLeave(index)"
            @click="showProductDetail(index)"
          >
            <div class="ygx_product_menu_img">
              <img style="width: 100%" :src="item.img" />
            </div>
            <div class="ygx_product_menu_title">{{ item.title }}</div>
            <div class="ygx_product_menu_subTitle">{{ item.subTitle }}</div>
          </div>
        </div>
      </div>
    </div>


    <div id="ygx_about" class="ygx_about">
      <div class="ygx_about_page_hone">
          <div class="ygx_about_page_hone_bg_img">
            <img src="../assets/ygx/about_bg.png"   />
          </div>
          <div  class="ygx_about_page_top_body">
            <img src="../assets/ygx/about_us_title_bg.png"  style="width:184px" />
            <div class="ygx_about_page_top_msg">
            <span class="ygx_about_page_top_msg_nore">最值得</span>
            <span class="ygx_about_page_top_msg_point">信赖</span>
            <span class="ygx_about_page_top_msg_nore">的合作伙伴，为您提供最</span>
            <span class="ygx_about_page_top_msg_point">优质的项目</span>
            <span class="ygx_about_page_top_msg_nore">与</span>
            <span class="ygx_about_page_top_msg_point">服务</span>
            </div>
          </div>
      </div>
      <div class="ygx_about_page_body">
        <div v-for="(item,index) in aboutUsList" :key="index" class="ygx_about_page_body_item" :style="item.bgImg">
          <div :class="index == 2 ? 'ygx_about_page_body_item_cover_hover' : 'ygx_about_page_body_item_cover'">
            <div class="ygx_about_page_body_item_title">{{item.title}}</div>
          <div class="ygx_about_page_body_item_line"></div>
          <div v-if="index == 1" class="ygx_about_page_body_item_msg">
              <div class="ygx_about_page_body_item_msg_item">
                <div class="ygx_about_page_body_item_msg_item_title">时间共享</div>
                <div class="ygx_about_page_body_item_msg_item_body">
                  <div class="ygx_about_page_body_item_msg_item_body_point"></div>
                  <div class="ygx_about_page_body_item_msg_item_body_sub">通过共享提高人均有效工作时间</div>
                </div>
                <div class="ygx_about_page_body_item_msg_item_body">
                  <div class="ygx_about_page_body_item_msg_item_body_point"></div>
                  <div class="ygx_about_page_body_item_msg_item_body_sub">平台化减少工作切换时间</div>
                </div>
              </div>
              <div class="ygx_about_page_body_item_msg_item">
                <div class="ygx_about_page_body_item_msg_item_title">空间共享</div>
                <div class="ygx_about_page_body_item_msg_item_body">
                  <div class="ygx_about_page_body_item_msg_item_body_point"></div>
                  <div class="ygx_about_page_body_item_msg_item_body_sub">全国一盘棋布局人力资源</div>
                </div>
                <div class="ygx_about_page_body_item_msg_item_body">
                  <div class="ygx_about_page_body_item_msg_item_body_point"></div>
                  <div class="ygx_about_page_body_item_msg_item_body_sub">以三线城市的成本干一、二线城市的工作</div>
                </div>
              </div>
              
          </div>
          <div v-else class="ygx_about_page_body_item_msg">{{item.msg}}</div>
          </div>
        </div>
      </div>

      <div class="ygx_about_page_view">
        <video-player class="video-player vjs-custom-skin vjs-big-play-centered"  ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
      </div>


      <div class="ygx_about_case">
          <div class="ygx_about_case_head">
            <div class="ygx_about_case_head_title">案例展示</div>
            <div class="ygx_about_case_head_line"></div>
          </div>
          <div class="ygx_about_case_item" v-for="(item,index) in caseList" :key="index">
            <img :src="item.imgA" class="ygx_about_case_img" />
            <div class="ygx_about_case_body">
              <div :class="index == 0 ? 'ygx_about_case_body_point' : 'ygx_about_case_body_point_b'"></div>
              <div class="ygx_about_case_body_line"></div>
              <div :class="(index < caseList.length - 1) ? 'ygx_about_case_body_point_c' : 'ygx_about_case_body_point'"></div>
            </div>
            <img :src="item.imgB" class="ygx_about_case_img">
          </div>
      </div>
    </div>

    <div id="ygx_link_us" class="ygx_link_us">
        <div class="ygx_link_page_top">
           <div class="ygx_link_page_top_bg_img">
            <img src="../assets/ygx/link_us_bg.png"   />
          </div>
          <div class="ygx_link_page_top_body">
            <div class="ygx_link_page_top_title">联系我们</div>
            <div class="ygx_link_page_top_sub_title">Get in touch with us</div>
            <div class="ygx_link_page_top_space"></div>
          </div>
        </div>

    <div class="ygx_link_page_body">
      <div class="ygx_link_page_body_info">
      <div class="ygx_link_page_body_item">
        <div class="ygx_link_page_body_item_title">联系方式</div>
        <div class="ygx_link_page_body_item_bottom">
          <div class="ygx_link_page_body_item_img"><img src="../assets/ygx/about_phone.png"  /></div>
          <div>
            <div class="ygx_link_page_body_item_first">联系电话：</div>
            <div class="ygx_link_page_body_item_first">0553-3911903</div>
          </div>
        </div>
        <div class="ygx_link_page_body_item_bottom">
          <div class="ygx_link_page_body_item_img"><img style="width:100%;height:100%" src="../assets/ygx/about_msg.png" /></div>
          <div>
            <div class="ygx_link_page_body_item_first">企业名称:</div>
            <div class="ygx_link_page_body_item_first">安徽云工享网络科技有限公司</div>
          </div>
        </div>
      </div>

      <div class="ygx_link_page_body_item">
        <div class="ygx_link_page_body_item_title">查看线路</div>
        <div class="ygx_link_page_body_item_bottom">
          <div class="ygx_link_page_body_item_img"><img src="../assets/ygx/about_loc.png"  /></div>
          <div>
            <div class="ygx_link_page_body_item_first">企业地址：</div>
            <div class="ygx_link_page_body_item_first">安徽省芜湖市镜湖区星隆国际城7号楼9层</div>
          </div>
        </div>
      </div>
      </div>
      <div class="ygx_solde_line"></div>


      
    </div>
    </div>

    <div id="ygx_news" class="ygx_news">
      <div class="ygx_news_left">
        <div class="common_title_sec">企业新闻</div>
        <div class="common_sub_title_sec">NEWS CENTER</div>
        <div class="common_space_sec"></div>
        <div class="ygx_news_body">
          <div
            :class="
              item.selTag ? 'ygx_news_body_item_sel' : 'ygx_news_body_item'
            "
            v-for="(item, index) in newsTypeList"
            :key="index"
            @click="newsTypeClick(index)"
          >
            <div
              class="ygx_news_body_item_space"
              :style="
                item.selTag
                  ? 'background-color: #fff;'
                  : 'background-color: #00343F;'
              "
            ></div>
            <div
              class="ygx_news_body_item_title"
              :style="item.selTag ? 'color: #fff;' : 'color: #000;'"
            >
              {{ item.title }}
            </div>
            <div
              class="ygx_news_body_item_img"
              :src="item.selTag ? item.imgSel : item.img"
            ></div>
          </div>
        </div>
      </div>
      <div class="ygx_news_right">
        <el-carousel :interval="10000" type="card" height="430px">
          <el-carousel-item v-for="(item, index) in showNewList" :key="index">
            <div class="ygx_news_right_banner" @click="showNewDetail(item, index)">
              <div class="ygx_news_right_banner_img">
                <img :src="item.coverImg" />
              </div>
              <div class="ygx_news_right_banner_msg">
                <div class="ygx_news_right_banner_msg_left">
                  {{ item.title }}
                </div>
                <div class="ygx_news_right_banner_msg_right">
                  <div class="ygx_news_right_banner_msg_right_day">
                    {{
                      item.releaseTime ? item.releaseTime.substring(8, 10) : ""
                    }}
                  </div>
                  <div class="ygx_news_right_banner_msg_right_month">
                    {{
                      item.releaseTime ? item.releaseTime.substring(0, 7) : ""
                    }}
                  </div>
                </div>
              </div>
              <div class="ygx_news_right_banner_bottom">
                <div class="ygx_news_right_banner_bottom_left"></div>
                <div
                  class="ygx_news_right_banner_bottom_right"
                >
                  <div class="ygx_news_right_banner_bottom_right_title">
                    了解更多
                  </div>
                  <img src="../assets/ygx/next.png" />
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    

    <div id="ygx_suggest" class="ygx_suggest">
      <div class="ygx_suggest_title">服务反馈</div>
      <div class="ygx_suggest_sub_title">
        Register customer information and return visit
      </div>
      <div class="ygx_suggest_body">
        <el-input
          v-model="sendDto.msg"
          style="margin: 10px 10px"
          placeholder="我们能帮您什么？"
        ></el-input>
        <el-input
          v-model="sendDto.name"
          style="margin: 10px 10px"
          placeholder="您的姓名？"
        ></el-input>
        <el-input
          v-model="sendDto.mobile"
          style="margin: 10px 10px"
          placeholder="您的电话？"
        ></el-input>
        <el-button type="primary"  @click="sendSubmitAction"
          >   发  送   </el-button
        >
      </div>
    </div>
  </div>
</template>


<script>
import aEntityAPI from "@/api/sysAPI.js";
import item from "../components/selector/item";
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'

export default {
  components: {
    videoPlayer
  },
computed: {
            player() {
                return this.$ref.videoPlayer.player
            }
        },
  data() {
    return {
      playerOptions: {
                    playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                    autoplay: false, // 如果为true,浏览器准备好时开始回放。
                    muted: false, // 默认情况下将会消除任何音频。
                    loop: false, // 是否视频一结束就重新开始。
                    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                    language: 'zh-CN',
                    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                    sources: [{
                        type: "video/mp4", // 类型
                        src: require("@/assets/ygx/ygx_logo.mp4"), // url地址
                    }],
                    poster: require("@/assets/ygx/banner.png"), // 封面地址
                    notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                    controlBar: {
                        timeDivider: true, // 当前时间和持续时间的分隔符
                        durationDisplay: true, // 显示持续时间
                        remainingTimeDisplay: false, // 是否显示剩余时间功能
                        fullscreenToggle: true // 是否显示全屏按钮
                    }
                },
      sendDto: {
        msg: "",
        mobile: "",
        name: "",
      },

      caseList:[
        {
          imgA:require("@/assets/ygx/case_1_1.png"),
          imgB:require("@/assets/ygx/case_1_2.png"),
        },{
           imgA:require("@/assets/ygx/case_2_1.png"),
          imgB:require("@/assets/ygx/case_2_2.png"),
        },{
           imgA:require("@/assets/ygx/case_3_1.png"),
          imgB:require("@/assets/ygx/case_3_2.png"),
        }
      ],
      bannerList:[
        require("@/assets/ygx/banner.png"),
        require("@/assets/ygx/bannerB.png"),
        require("@/assets/ygx/bannerC.png"),
      ],

      topIndex: 1,
      meunIndex: 0,
      newsCaseList: [],
      aboutUsList:[
        {
          title:"公司简介",
          msg:"安徽云工享网络科技有限公司基于互联网、大数据构建人力资源生态圈，为用工企业、人力资源公司、自由职业者提供灵活用工、异地转移、服务外包、产业园服务等服务的人力资源服务平台。",
          bgImg:"background-image: url("+require("@/assets/ygx/about_us_bg_a.png")+")"
        },
        {
          title:"我们的价值",
          msg:"",
          bgImg:"background-image: url("+require("@/assets/ygx/about_us_bg_b.png")+")"
        },{
          title:"办公环境",
          msg:"",
          bgImg:"background-image: url("+require("@/assets/ygx/about_us_bg_c.png")+")"
        }
      ],
      newsTypeList: [
        {
          title: "最新资讯",
          selTag: true,
          code: "",
          img: require("@/assets/ygx/hot.png"),
          imgSel: require("@/assets/ygx/hot_sel.png"),
          news: [],
        },
        {
          title: "行业动态",
          selTag: false,
          code: "NEWS_TYPE_YGX_RUUN",
          img: require("@/assets/ygx/sound.png"),
          imgSel: require("@/assets/ygx/sound_sel.png"),
          news: [],
        },
      ],
      showNewList: [], // 展示的新闻
      productTagList: [
        {
          title: "平台人数",
          subTitle: "6000+",
          img: require("@/assets/ygx/plate_num.png"),
        },
        {
          title: "总入驻企业",
          subTitle: "70家",
          img: require("@/assets/ygx/company_num.png"),
        },
        {
          title: "办公人数",
          subTitle: "260+",
          img: require("@/assets/ygx/work_num.png"),
        },
      ],
      productList: [
        {
          title: "灵活用工",
          subTitle: "用工合规、类型丰富、资源共享",
          desc: '通过整合人力资源分散产能，与企业的灵活用工需求进行大数据在线智能匹配。为传统企业进行互 联网化改造，推动企业端供给侧改革，为企业“降成本，促活力”，为个人“促创业，增收入”。',
          img: require("@/assets/ygx/product_0.png"),
          
        },
        {
          title: "产业园运营",
          subTitle: "市中心、政企合作、政策好",
          desc: "实训基地、营销中心、营运中心、产业园服务公司、技术中心、人力资源部、结算中心等职能部门，支撑业务快速成长并给入园的客户提供优质的有保障的服务。",
          img: require("@/assets/ygx/product_1.png"),
        },
        {
          title: "异地岗位共享",
          subTitle: "招聘支持、拎包入住、成本优势",
          desc: "为一二线城市企业提供工作异地转移服务，基于数字化实现人力资源异地共享，降低企业用工成本。",
          img: require("@/assets/ygx/product_2.png"),
          
        },
      ],
    };
  },

  created() {},

  mounted() {
    let that = this;
    that.getYgxNewsHome();
  },

  methods: {


      // 播放回调
            onPlayerPlay(player) {
                console.log('player play!', player)
                this.$refs.videoPlayer.player.play() // 播放
            },

            // 暂停回调
            onPlayerPause(player) {
                this.$refs.videoPlayer.player.pause() // 暂停
            },

            // 视频播完回调
            onPlayerEnded($event) {
                console.log(player)
                this.$refs.videoPlayer.player.src(src) // 重置进度条复制代码
            },

    //点击发送按钮
    sendSubmitAction() {
      var that = this;
      if (that.sendDto.name == "") {
        that.$message({
          showClose: true,
          message: "请填写您的姓名",
          type: "warning",
        });
        return;
      }
      if (that.sendDto.mobile == "") {
        that.$message({
          showClose: true,
          message: "请填写您的联系方式",
          type: "warning",
        });
        return;
      }
      if (that.sendDto.msg == "") {
        that.$message({
          showClose: true,
          message: "请填写反馈内容",
          type: "warning",
        });
        return;
      }
     aEntityAPI.request("NewsService", "saveYgxNewsSuggest",{suggestDto:JSON.stringify(that.sendDto)})
        .then((res) => {
          if (res.data.success) {
            that.$message({
              message: "发送成功",
              type: "success",
            });
          } else {
            that.$message({
              showClose: true,
              message: res.data.msg ? res.data.msg : "发送数据失败",
              type: "warning",
            });
          }
        })
        .catch((res) => {});
    },

    //跳转到新闻详情
    showProductDetail(index) {
      var url = window.location.protocol + "//" + window.location.host + "/ygx.html#/ygxProductDetail?index=" + index;
                window.open(url);
    },
    //跳转到新闻详情
    showNewDetail(item, index) {
      var url = window.location.protocol + "//" + window.location.host + "/ygx.html#/ygxNews";
                window.open(url);
    },


    //点击选择了新闻类型
    newsTypeClick(indexTag) {
      let that = this;
      var itemList = that.newsTypeList;
      for (let index = 0; index < itemList.length; index++) {
        var item = itemList[index];
        if (index == indexTag) {
          item.selTag = true;
          that.showNewList = item.news;
        } else {
          item.selTag = false;
        }
      }
    },

    //鼠标移到了产品菜单
    meunMouseOver(index) {
      this.meunIndex = index;
    },
    //鼠标移到了产品菜单
    meunMouseLeave(index) {
      
    },

    //鼠标移到了产品明细菜单
    meunItemOver(indexTag) {
      let that = this;
      var itemList = that.productList[that.meunIndex].itemList;
      for (let index = 0; index < itemList.length; index++) {
        var item = itemList[index];
        if (index == indexTag) {
          item.selTag = true;
        } else {
          item.selTag = false;
        }
      }
    },
    //鼠标移开了产品明细菜单
    meunItemLeave(indexTag) {
      let that = this;
      var itemList = that.productList[that.meunIndex].itemList;
      for (let index = 0; index < itemList.length; index++) {
        var item = itemList[index];
        item.selTag = false;
      }
    },

    //产品点击查看更多
    productMoreClick() {},

    //获取云工享企业新闻列表
    getYgxNewsHome() {
      let that = this;
      aEntityAPI.request("NewsService", "getYgxNewsHome").then((res) => {
        if (res.data.success) {
          var dataDict = res.data.data;
          if (dataDict.all != null && dataDict.all.length > 0) {
            that.newsTypeList[0].news = dataDict.all;
            this.showNewList = dataDict.all;
          }
          if (dataDict.run != null && dataDict.run.length > 0) {
            that.newsTypeList[1].news = dataDict.run;
          }
        }
      });
    },
  },
};
</script>


<style>
.ygx_body_index {
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
}

.ygx_banner {
  width: 100%;
  flex-direction: column;
  display: flex;
}

.ygx_banner_img{
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.banner_img_ygx{
  height: 100%;

}

.ygx_banner_body {
  width: 100%;
  position: relative;
  background-image: url("../assets/ygx/ygx_banner_a.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.ygx_banner_body::before {
  float: left;
  padding-top: 35%;
  content: "";
}

.ygx_banner_body::after {
 display: block;
 clear: both;
 content: "";
}

.ygx_banner_body_msg{
  position: absolute;
  top: 40%;
  left: 20%;
  display: flex;
  flex-direction:column;
  width: 800px;
  z-index: 20;
  
}

.ygx_banner_title {
  
  font-size: 73px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 92px;
  margin-bottom: 20px;
}

.ygx_banner_title_sub {


  font-size: 37px;
  font-family: Source Han Sans SC;
  font-weight: 300;
  color: #ffffff;
  line-height: 47px;
  
}

.common_title_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.common_title_body_first {
  font-size: 32px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #111111;
  line-height: 46px;
}

.common_title_body_second {
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}

.ygx_product {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 110px;
}

.ygx_product_body {
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ygx_product_menu {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 40px 0px;
}

.ygx_product_menu_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 375px;
  height: 375px;
  background: #ffffff;
  box-shadow: 0px 2px 43px 0px rgba(21, 113, 179, 0.15);
  opacity: 0.97;
  border-radius: 4px;
  cursor: pointer;
}

.ygx_product_menu_item:hover {
  box-shadow: 0px 2px 43px 0px rgba(21, 113, 179, 0.56);
  opacity: 0.97;
  border-radius: 4px;
}

.ygx_product_menu_img {
  width: 105px;
  height: 105px;
}

.ygx_product_menu_title {
  font-size: 26px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #111111;
  line-height: 37px;
  margin: 20px 0px;
}

.ygx_product_menu_subTitle {
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  line-height: 34px;
}

.ygx_product_detail {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ygx_product_detail_left {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ygx_product_detail_left_one {
  font-size: 32px;
  font-family: Source Han Sans SC;
  font-weight: 600;
  color: #111111;
  line-height: 48px;
}

.ygx_product_detail_left_sec {
  font-size: 25px;
  font-family: Source Han Sans SC;
  font-weight: 600;
  color: #111111;
  line-height: 48px;
}

.ygx_product_detail_left_desc {
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  margin: 10px 0px;
}

.ygx_know_more {
  background: #0376f7;
  width: 160px;
  height: 3px;
  margin-top: 20px;
  /* line-height: 45px;
  text-align: center;
  font-size: 16px;
  text-align: center;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
  
  cursor: pointer; */
}

.ygx_product_detail_right {
  flex: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 600px;
}

.ygx_product_detail_item {
  width: 240px;
  height: 300px;
  margin-left: 15px;
  margin-bottom: 15px;
  background: #ffffff;
  box-shadow: 0px 2px 70px 0px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ygx_product_detail_item:hover {
  background: #0376f7;
}

.ygx_product_detail_item:hover .ygx_product_detail_item_title {
  color: #fff;
}

.ygx_product_detail_item:hover .ygx_product_detail_item_subTitle {
  color: #fff;
}

.ygx_product_detail_item_img {
  width: 45px;
  height: 45px;
}

.ygx_product_detail_item_title {
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #111111;
  line-height: 24px;
  margin: 20px 0px;
}

.ygx_product_detail_item_subTitle {
  font-size: 14px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  margin: 0px 20px;
}

.ygx_product_detail_one {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ygx_product_detail_sec {
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ygx_product_detail_three {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ygx_product_detail_three_left {
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

}

.ygx_product_detail_three_right {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

}

.ygx_product_detail_one_right {
  display: flex;
  flex-direction: column;
}

.ygx_product_bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 110px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
 background-color: #ffffff55;
 
}

.ygx_product_bottom_item {
  width: 220px;
  height: 100px;
  margin: 0px 120px;

  background: rgba(0, 0, 0, 0);
  box-shadow: 0px 2px 70px 0px rgba(0, 0, 0, 0.0);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ygx_product_bottom_img {
  width: 44px;
  height: 44px;
}

.ygx_product_bottom_title {
  font-size: 16px;
font-family: Source Han Sans SC;
font-weight: 400;
color: #000000;

}

.ygx_product_bottom_subTitle {
  font-size: 32px;
font-family: Source Han Sans SC;
font-weight: 500;
color: #2468F2;
  margin-bottom: 10px;
}

.ygx_news {
  width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 110px;
  height: 450px;
}
.ygx_news_left {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ygx_news_right {
  flex: 2;
}

.ygx_news_body {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;
}

.ygx_news_body_item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  height: 40px;
}

.ygx_news_body_item_sel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px;
  height: 40px;
  background-image: url("../assets/ygx/news_pop.png");
  background-size: 100% 100%;
}

.ygx_news_body_item_space {
  width: 6px;
  height: 6px;

  margin: 0px 20px;
}

.ygx_news_body_item_title {
}

.ygx_news_body_item_img {
  width: 24px;
  height: 24px;
  float: right;
  margin-right: 40px;
}

.common_title_sec {
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #111111;
  line-height: 35px;
}

.common_sub_title_sec {
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #111111;
  line-height: 35px;
}

.common_space_sec {
  width: 20px;
  height: 4px;
  margin-top: 10px;
  background: #0376f7;
}

.ygx_about {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  background-color: #ffffff;
  padding-bottom: 80px;
}

.ygx_about_page_hone {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center;
  background-image: url("../assets/ygx/about_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat; */
}

.ygx_about_page_hone_bg_img {
  padding-bottom: 20%;
}

.ygx_about_page_hone_bg_img img{
  width: 100%;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
}

.ygx_suggest {
  width: 100%;
  height: 386px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/ygx/suggest_bg.png");
  background-size: 100% 100%;
  margin-top: 110px;
}

.ygx_suggest_title {
  font-size: 49px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 52px;
}

.ygx_suggest_sub_title {
  font-size: 18px;
  font-family: Kozuka Gothic Pr6N;
  font-weight: normal;
  color: #ffffff;
  line-height: 31px;
  margin: 40px 0px;
}

.ygx_suggest_body {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ygx_news_right_banner {
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin: 5%;
  box-shadow: 0px 2px 70px 0px rgba(0, 0, 0, 0.07);
}

.ygx_news_right_banner_img {
  width: 100%;
  height: 250px;
  flex: 2;
}

.ygx_news_right_banner_img img {
  width: 100%;
  height: 100%;
}

.ygx_news_right_banner_msg {
  flex: 3;
  display: flex;
  flex-direction: row;
  margin: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ygx_news_right_banner_msg_left {
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #1a1a1a;
  line-height: 28px;
  margin-left: 10%;
}

.ygx_news_right_banner_msg_right {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 10%;
}

.ygx_news_right_banner_msg_right_day {
  font-size: 32px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #011935;
  line-height: 40px;
}

.ygx_news_right_banner_msg_right_month {
  font-size: 14px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #999999;
  line-height: 30px;
}

.ygx_news_right_banner_bottom {
  display: flex;
  flex-direction: row;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ygx_news_right_banner_bottom_left {
  width: 20px;
  height: 3px;
  background: #00343f;
  margin-left: 10%;
}

.ygx_news_right_banner_bottom_right {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  margin-right: 10%;
}

.ygx_news_right_banner_bottom_right_title {
  font-size: 14px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #4d4d4d;
  line-height: 24px;
  margin-right: 20px;
}

.ygx_news_right_banner_bottom_right_title img {
  width: 22px;
  height: 22px;
}







.ygx_about_page_top_body{
  position:absolute;
  left: 0px;
  top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
}

.ygx_about_page_top_title{
    font-size: 24px;
font-family: SourceHanSansCN;
font-weight: 400;
color: #FFFFFF;
line-height: 50px;

}

.ygx_about_page_top_sub_title{
    width: 184px;
height: 73px;

}

.ygx_about_page_top_msg{
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.ygx_about_page_top_msg_nore{
  font-size: 34px;
font-family: SourceHanSansCN;
font-weight: 50;
color: #FFFFFF;
line-height: 52px;
letter-spacing:5px;
}

.ygx_about_page_top_msg_point{
font-size: 34px;
font-family: SourceHanSansCN;
font-weight: 600;
color: #FFFFFF;
line-height: 52px;
letter-spacing:5px;
}

.ygx_about_page_body{
    display: flex;
    width: 1200px;
    height: 380px;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 100px;
    margin-bottom: 20px;
   
   
}

.ygx_about_page_body_item{
  
  width: 350px;
  height: 350px;
  
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.ygx_about_page_body_item_cover{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #00000088;
}

.ygx_about_page_body_item_cover_hover{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #00000088;
  
}

.ygx_about_page_body_item_cover_hover:hover{
  background-color: #00000000;
  background-image: url("../assets/ygx/ygx_workSpace.png");
  background-size: 100% 100%;
}

.ygx_about_page_body_item_title{
  font-size: 24px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #FFFFFF;
margin-top: 20px;
margin-left: 15px;
}


.ygx_about_page_body_item_msg{
  font-size: 18px;
font-family: Source Han Sans SC;
font-weight: 400;
line-height: 28px;
color: #FFFFFF;
margin-left: 15px;
margin-right: 15px;
margin-bottom: 20px;
opacity: 0;
display: flex;
flex-direction: column;
}

.ygx_about_page_body_item:hover .ygx_about_page_body_item_msg{
  opacity: 1;
}


.ygx_about_page_body_item_msg_item{
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}

.ygx_about_page_body_item_msg_item_title{
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #FFFFFF;
  width: 128px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 1);
}

.ygx_about_page_body_item_msg_item_body{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ygx_about_page_body_item_msg_item_body_point{
width: 6px;
height: 6px;
background: #FFFFFF;
border-radius: 50%;
margin-right: 10px;
}

.ygx_about_page_body_item_msg_item_body_sub{
  font-size: 18px;
font-family: Source Han Sans SC;
font-weight: 400;
color: #FFFFFF;
}


.ygx_about_page_body_item_line{
  width: 60px;
height: 3px;
margin-left: 15px;
margin-top: 10px;
margin-bottom: 15px;
background: #FFFFFF;
}


.ygx_about_page_view{
  width: 1200px;
  height: 618px;
}



.ygx_about_case{
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
}

.ygx_about_case_head{
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  align-items: center;
  justify-content: center;

}

.ygx_about_case_head_title{
font-size: 30px;
font-family: Source Han Sans SC;
font-weight: bold;
color: #333333;
margin-bottom: 20px;
}

.ygx_about_case_head_line{
  width: 60px;
height: 3px;
background: #212121;
}

.ygx_about_case_item{
  width: 100%;
  display: flex;
  height: 350px;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;

}

.ygx_about_case_img{
  width: 400px;
  object-fit: contain;
  
}

.ygx_about_case_body{
  width: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ygx_about_case_body_line{
  width: 5px;
  background-color: #0376f7;
  flex: 2;
}

.ygx_about_case_body_point{
  width: 20px;
  height: 20px;
  background-image: url("../assets/ygx/ygx_point_a.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.ygx_about_case_body_point_b{
  width: 20px;
  height: 10px;
  background-image: url("../assets/ygx/ygx_point_b.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.ygx_about_case_body_point_c{
  width: 20px;
  height: 10px;
  background-image: url("../assets/ygx/ygx_point_c.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}




/* 关于我们   开始 */

.ygx_link_us {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  background-color: #ffffff;
}

.ygx_link_page_top {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}


.ygx_link_page_top_bg_img {
  padding-bottom: 15%;
}

.ygx_link_page_top_bg_img img{
  width: 100%;
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
}

.ygx_link_page_top_body{
    position:absolute;
  left: 0px;
  top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
}

.ygx_link_page_top_title {
  font-size: 48px;
font-family: SourceHanSansCN;
font-weight: 400;
color: #FFFFFF;
}

.ygx_link_page_top_sub_title {
  font-size: 48px;
font-family: SourceHanSansCN;
font-weight: 400;
color: #FFFFFF;
margin: 20px 0px;
}

.ygx_link_page_top_space {
  width: 89px;
height: 3px;
background: #FFFFFF;
}

.ygx_link_page_body {
  display: flex;
  width: 1200px;
  flex-direction: column;
  align-items: flex-start;
}

.ygx_link_page_body_info{
  margin-top: 60px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
}

.ygx_link_page_body_item {
  display: flex;
  flex-direction: column;
}


.ygx_link_page_body_item_title{
    font-size: 35px;
font-family: SourceHanSansCN;
font-weight: 400;
color: #333333;
line-height: 29px;
}

.ygx_link_page_body_item_bottom{
    display: flex;
  flex-direction: row;

}

.ygx_link_page_body_item_img{
width: 41px;
height: 41px;
margin-top: 20px;
margin-right: 40px;
}
.ygx_link_page_body_item_img img{
width: 100%;

}

.ygx_link_page_body_item_first{
   font-size: 24px;
font-family: Source Han Sans SC;
font-weight: 400;
color: #333333;
line-height: 76px;
}
.ygx_solde_line {
  width: 100%;
  border: 1px dashed #00000099;
  height: 0;
}





/* 关于我们   结束 */
</style>