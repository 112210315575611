<template>
  <div class="ygx_news_page">
    <img style="width:100%" src="../assets/ygx/news_bg.png" alt="">
    <div class="ygx_news_page_body">
      <div class="ygx_news_page_body_left">
    
        <div
          class="ygx_news_page_body_left_item"
          v-for="(item, index) in meunList"
          :key="index"
        

          @click="upReferData(index)"
        >
          <div :class="item.selTag ? 'ygx_news_page_body_left_point_a' : 'ygx_news_page_body_left_point_b'"></div>
          <div class="ygx_news_page_body_left_title">{{ item.title }}</div>
        </div>
      </div>
      <div class="ygx_news_page_body_right">
        <div class="ygx_news_page_body_right_item" v-for="(item,index) in showNewList" :key="index" @click="showDetail(item)">
          
          <div class="ygx_news_page_body_right_item_img">
            <img :src="item.coverImg" alt="" />
          </div>
          <div class="ygx_news_page_body_right_item_body">
            <div class="ygx_news_page_body_right_item_body_title">
              {{ item.title }}
            </div>
            <div class="ygx_news_page_body_right_item_body_time">
              {{ item.releaseTime }}
            </div>
            <div class="ygx_news_page_body_right_item_body_intor">
              {{ item.intro }}
            </div>
          </div>
        </div>

        <el-button style="margin:40px" type="primary"  @click="getYgxNewsList" v-if="moreTag">更多..</el-button>
      </div>
    </div>
  </div>
</template>


<script>
import aEntityAPI from "@/api/sysAPI.js";

export default {
  components: {},

  data() {
    return {
      pageSize: 5,
      pageNo: 0,
      moreTag: true,
      showNewList: [], // 展示的新闻
      meunList: [
        {
          selTag: true,
          code: "",
          title: "企业新闻",
        },
        {
          selTag: false,
          code: "'NEWS_TYPE_YGX_FOUSE'",
          title: "媒体聚焦",
        },
        {
          selTag: false,
          code: "'NEWS_TYPE_YGX_RUUN'",
          title: "行业动态",
        },
        {
          selTag: false,
          code: "'NEWS_TYPE_YGX_SUPPER'",
          title: "政策支持",
        },
      ],
    };
  },

  created() {},

  mounted() {
    let that = this;
    that.getYgxNewsList();
  },

  methods: {

    meunItemOver(indexTag){
      let that = this;
      for (let index = 0; index < that.meunList.length; index++) {
        var item = that.meunList[index];
        item.selTag = indexTag == index ? true : false;
      }
      that.$set(that,"meunList",that.meunList);


    },

    showDetail(item){
      var url = window.location.protocol + "//" + window.location.host + "/ygx.html#/ygxNewsDetail?id=" + item.id;
                window.open(url);
    },

    upReferData(index){
      let that = this;
      that.meunItemOver(index);
      that.pageNo = 0;
      that.showNewList = [];
      that.getYgxNewsList();
    },
    
    //获取云共享企业新闻列表
    getYgxNewsList() {
      let that = this;
      var code = "'NEWS_TYPE_YGX_FOUSE','NEWS_TYPE_YGX_RUUN','NEWS_TYPE_YGX_SUPPER'";

      that.meunList.forEach((item) => {
        if (item.selTag && item.code != "") {
          code = item.code;
        }
      });

      let params = {
        start: this.pageSize * this.pageNo,
        limit: this.pageSize,
        q: {
          dictCode: code,
          fields: [
            "id",
            "title",
            "intro",
            "category",
            "categoryId",
            "coverImg",
            "releaseTime",
            "status",
            "content",
          ],
          sort: "id__desc",
        },
      };

      aEntityAPI
        .request("NewsService", "getYgxNewsList", {qp:JSON.stringify(params)})
        .then((response) => {
          that.grid_loading = false;
          if (response.data.success) {
            that.moreTag = true;
            var dataList = response.data.data;
            if (dataList.length > 0) {
              that.showNewList = that.showNewList.concat(dataList);
              if (dataList.length >= that.pageSize) {
                that.pageNo = that.pageNo + 1;
              } else {
                that.moreTag = false;
                // that.$message.sccess("已加载全部数据！");
              }
            } else {
              that.moreTag = false;
              // that.$message.sccess("已加载全部数据！");
            }
          } else {
            that.$message.error("查询新闻出错");
          }
        });
    },
  },
};
</script>


<style>
.ygx_news_page {
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
}

.ygx_news_page_top {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 700px;
  background-image: url("../assets/ygx/link_us_bg.png");
  background-size: 100% 100%;
}

.ygx_news_page_body {
  display: flex;
  width: 1200px;
  flex-direction: row;
  min-height: 700px;
  margin-top: 60px;
}

.ygx_news_page_body_left {
  display: flex;
  width: 30%;
  flex-direction: column;
  min-height: 700px;
}

.ygx_news_page_body_left_item {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 60px;
  align-items: center;
  cursor: pointer;
}



.ygx_news_page_body_left_point_a {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #000;
}

.ygx_news_page_body_left_point_b {
  width: 5px;
  height: 2px;
  background-color: #000;
}

.ygx_news_page_body_left_title {
  font-size: 26px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #444444;
  line-height: 18px;
  margin-left: 10px;
}

.ygx_news_page_body_left_title:hover {
  font-weight: 600;

}

.ygx_news_page_body_right {
  display: flex;
  flex: 2;
  flex-direction: column;
  min-height: 700px;
}

.ygx_news_page_body_right_item {
  display: flex;
  flex-direction: row;

  padding-bottom: 20px;
  border-bottom: 1px solid #E5E5E5;
  cursor: pointer;
}

.ygx_news_page_body_right_item_img {
  width: 270px;
  height: 190px;
}

.ygx_news_page_body_right_item_img img {
  width: 270px;
  height: 190px;
}

.ygx_news_page_body_right_item_body {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  
}

.ygx_news_page_body_right_item_body_title {
  font-size: 18px;
  font-family: FZLanTingHeiS-DB-GB;
  font-weight: bold;
  color: #000000;
  line-height: 24px;
  margin-top: 10px;
}

.ygx_news_page_body_right_item_body_time {
  font-size: 13px;
  font-family: Myriad Pro;
  font-weight: 400;
  color: #c5c5c5;
  line-height: 24px;
  margin:20px 0px;
}

.ygx_news_page_body_right_item_body_intor {
  font-size: 14px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #000000;
  line-height: 24px;
}
</style>