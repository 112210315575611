<template>
  <div class="ygx_body">
    <div id="ygx_header" class="ygx_header">
      <div class="ygx_header_body">
        <div class="ygx_head_body_left">
          <div class="ygx_logo" @click="clickHomeMenum('#ygx_banner',1)">
            <img src="../assets/ygx/logo.png" />
          </div>
        </div>
        <div class="ygx_menu" >
          <ul>
            <li :class="{ active: topIndex == 1 }">
              <a @click="clickHomeMenum('#ygx_banner',1)">首页</a>
            </li>
            <li :class="{ active: topIndex == 2 }">
              <a @click="clickHomeMenum('#ygx_product',2)">产品介绍</a>
            </li>
             <li :class="{ active: topIndex == 3 }">
              <a @click="clickHomeMenum('#ygx_about',3)">关于我们</a>
            </li>
            <li :class="{ active: topIndex == 4}">
              <a @click="clickHomeMenum('#ygx_link_us',4)">联系我们</a>
            </li>
             <li :class="{ active: topIndex == 5 }">
              <a @click="clickHomeMenum('#ygx_news',5)">企业新闻</a>
            </li>
           
            
          </ul>
        </div>
      </div>
    </div>
    <div class="ygx_container">
      <router-view></router-view>
    </div>

    <div class="ygx_footer">
      <div class="ygx_footer_menu">
        <ul>
           <li :class="{ active: topIndex == 1 }">
              <a @click="clickHomeMenum('#ygx_banner',1)">首页</a>
            </li>
            <li :class="{ active: topIndex == 2 }">
              <a @click="clickHomeMenum('#ygx_product',2)">产品介绍</a>
            </li>
             <li :class="{ active: topIndex == 3 }">
              <a @click="clickHomeMenum('#ygx_about',3)">关于我们</a>
            </li>
            <li :class="{ active: topIndex == 4}">
              <a @click="clickHomeMenum('#ygx_link_us',4)">联系我们</a>
            </li>
             <li :class="{ active: topIndex == 5 }">
              <a @click="clickHomeMenum('#ygx_news',5)">企业新闻</a>
            </li>
        </ul>
      </div>
      <div class="ygx_footer_line"></div>
      <div class="ygx_footer_info">
        Copyright ©{{ yearTag }}-现在 皖ICP备2020006912号-6 ICP证书:
        皖B2-20180057
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      topIndex: 1,
      yearTag: "2021",
      activeHeader: "header",
      showMeunTag:true,
    };
  },

  created() {},

  methods: {
    clickHomeMenum(selector,index) {
      let that = this;
      that.topIndex = index;
      if(that.showMeunTag == false){
        var url = window.location.protocol + "//" + window.location.host + "/ygx.html#/ygx";
          window.location.href = url;
          that.showMeunTag = true;
          window.location.reload(true);
      }
      document.querySelector(selector).scrollIntoView({behavior: "smooth"});
      
      
    },
  },

  mounted() {
    var that = this;
    
    if (that.$route.path != null && that.$route.path != "/ygx") {
        that.showMeunTag = false;

    }else{
      that.showMeunTag = true;
    }

    if(that.$route.path == "/ygxProductDetail"){
      that.topIndex = 2;
    }

    var dateDict = new Date();
    this.yearTag = dateDict.getFullYear();
  },
};
</script>
<style>
body {
  overflow: auto;
}

.ygx_body {

  background-color: #2d3a4b;
  min-width: 1200px;
   width: 100%;
}

.ygx_container {
  display: flex;
  width: 100%;
  background-color: #fff;
  min-height: 500px;
}

.ygx_header {
  height: 100px;
  background: rgba(0, 0, 0, 0.36);
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 22;
}

.ygx_header_body {
  width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ygx_head_body_left {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
}

.ygx_logo {
  width: 210px;
  height: 40px;
  display: inline-block;
  overflow: hidden;
}

.ygx_logo:hover {
  cursor: pointer;
}

.ygx_logo img {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.ygx_menu {
  width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000000;
  height: 100%;
}

.ygx_menu:hover {
  cursor: pointer;
}

.ygx_menu ul > li {
  padding: 10px 20px;
}

.ygx_menu ul > li {
  padding: 10px 20px;
}

.ygx_menu ul > li {
  float: left;

  font-size: 16px;
  color: #fff;
}

.ygx_menu ul > li > a {
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
}

.ygx_menu ul .active {
  border-bottom: 2px solid #409eff;
}

.ygx_footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 165px;
  color: #fff;
  background-color: #404040;
}

.ygx_footer_line {
  width: 1200px;
  background-color: #c2c2c277;
  height: 1px;
}

.ygx_footer_info {
  font-size: 16px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #c2c2c2;
  line-height: 36px;
}

.ygx_footer_menu {
  width: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000000;
}

.ygx_footer_menu:hover {
  cursor: pointer;
}

.ygx_footer_menu ul > li {
  padding: 10px 20px;
}

.ygx_footer_menu ul > li {
  padding: 10px 20px;
}

.ygx_footer_menu ul > li {
  float: left;

  font-size: 16px;
  color: #fff;
}

.ygx_footer_menu ul > li > a {
  font-size: 16px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #c2c2c2;
  line-height: 36px;
}

.ygx_footer_menu ul .active {
  border-bottom: 2px solid #409eff;
}

@font-face {
  font-family: ZKGDH;
  src: url("../font/iconfont_zkgdh.TTF");
}
</style>